<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            商品与订单
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <h3>商品查询:</h3>
                <p>
                    为了让您快速查询所需要的产品，一方科技提供搜索框查找。</p>
                <p>
                    在页面顶部输入商品的中文名/英文名/CAS NO/产品编号，点击搜索，即可找到您需要的商品。</p>
                <br>
                <img src="@/assets/img/rp3.jpg" alt="">
                <br>

                <h3>商品购买:</h3>
                <p>想要购买商品吗?伊普商城为您提供多种方式将您心仪的商品添加到购物车，更加方便快捷。</p>
                <br>
                <div class="inline-block">
                    添加商品到购物车步骤详解：
                </div><br>
                <br>
                <p>1、登录您的账户后，通过搜索栏查找您需要的产品，例如: 维生素A</p>
                <br>
                <img src="@/assets/img/rp4.jpg" alt="">
                <br>
                <p>2、点击左侧“品牌”，“货期”可以筛选出您需要的产品</p>
                <br>
                <img src="@/assets/img/rp5.jpg" alt="">
                <br>
                <p>3、筛选到您需要的产品时，如果已确定产品的价格和货期等都符合您的要求，您可以在此直接点击商品信息的右侧“加入购物车”按钮，此时该产品已成功加入到
                    购物车中;</p>
                <br>
                <img src="@/assets/img/rp6.jpg" alt="">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "registerAndLogin"
}
</script>

<style scoped>

</style>
